import { atom } from "jotai";

export type createUserDetailsType = {
  email: string;
  password: string;
  phoneNumber: string;
  fullName: string;
  siteUrl: string;
};

export const createUserDetails = atom<createUserDetailsType | undefined>();

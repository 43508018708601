import React, { useCallback, useMemo, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AssessmentIcon from '@mui/icons-material/Assessment';
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import LogoutIcon from "@mui/icons-material/Logout";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import logo from "../../assets/logo.png";

const SideNavbar: React.FC = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  const location = useLocation(); // Get the current URL

  const currentKeyFunc = useCallback(() => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  }, [location.pathname]);

  const currentKey = useMemo(() => currentKeyFunc(), [currentKeyFunc]);

  return (
    <nav className="sidebar-navigation">
      <Typography className="logo">
        <img src={logo} width="70" alt="logo" />
      </Typography>
      <ul>
        <Link to="statistics">
          <li className={currentKey === "statistics" ? "active" : "inactive"}>
            <QueryStatsIcon />
            <span className="tooltip">סטטיסטיקות</span>
          </li>
        </Link>
        <Link to="reports">
          <li className={currentKey === "reports" ? "active" : "inactive"}>
            <AssessmentIcon />
            <span className="tooltip">דוחות</span>
          </li>
        </Link>
        <Link to="keys">
          <li className={currentKey === "keys" ? "active" : "inactive"}>
            <KeyIcon />
            <span className="tooltip">מילות מפתח</span>
          </li>
        </Link>
        <Link to="comps">
          <li className={currentKey === "comps" ? "active" : "inactive"}>
            <SportsKabaddiIcon />
            <span className="tooltip">מתחרים</span>
          </li>
        </Link>
        <Link to="news">
          <li className={currentKey === "news" ? "active" : "inactive"}>
            <NewspaperIcon />
            <span className="tooltip">עדכוני מערכת</span>
          </li>
        </Link>
        <Link to="/login" onClick={handleLogout}>
          <li>
            <LogoutIcon />
            <span className="tooltip">התנתקות</span>
          </li>
        </Link>
      </ul>
    </nav>
  );
};

export default SideNavbar;

import { collection, query, orderBy, limit, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db } from "../../firebaseConfig";
import { FirestoreReport, LighthouseReport } from '../../types/report';

export interface Report {
  id: string;
  created_at: Timestamp;
  report: unknown; // Replace with your report structure
}

const converter = {
    toFirestore: (data: FirestoreReport) => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot
    ): FirestoreReport => {
      const data = snapshot.data();
      return {
        id: snapshot.id,
        created_at: data.created_at,
        report: data.report as LighthouseReport
      };
    },
  };


export const getLatestReport = async (siteId: string): Promise<FirestoreReport | null> => {
    const reportsRef = collection(db, 'sites', siteId, 'reports').withConverter(converter);
  try {
    const q = query(
      reportsRef,
      orderBy('createdAt', 'desc'),
      limit(1)
    );
    
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return null;
    }

    return snapshot.docs[0].data();
  } catch (error) {
    console.error('Error fetching latest report:', error);
    throw error;
  }
};

import React from "react";
import "./index.scss";

import { Box } from "@mui/material";

const Footer: React.FC = () => {
  return <Box className="footer">כל הזכויות שמורות ל WiseFox Technologies</Box>;
};

export default Footer;

import axios from "axios";
import { useMutation } from "react-query";
import { baseURL } from "../consts";
import { getToken } from "../authUtils";

const fetchKeywords = async (keywordId: string) => {
  const token = await getToken();
  const data = await axios.delete(`${baseURL}/users/keywords/${keywordId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data?.data;
};

export const useDeleteKeyword = (onSuccess: any, onError?: any) => {
  const mutation = useMutation({
    mutationFn: (keywordId: string) => fetchKeywords(keywordId),
    onSuccess: onSuccess,
  });

  return mutation;
};

import React from "react";
import { Box, Typography } from "@mui/material";
import "./index.scss";
import NewsItem from "./NewsItem";
import { useGetNews } from "../../api/hooks/useGetNews";
import LoadingPattern from "../../Components/LoadingPattern";
import NewspaperIcon from "@mui/icons-material/Newspaper";

const NewsPage: React.FC = () => {
  const { data: items, isLoading } = useGetNews();
  return (
    <Box sx={{ padding: 2 }} className="mainCharts">
      <Typography component="h1" className="textHeader">
        <NewspaperIcon className="topIcon" /> עדכוני מערכת
      </Typography>
      {isLoading && <LoadingPattern />}
      <Box>
        {items &&
          items.map(
            (item: { created_at: string; title: string; text: string }) => (
              <NewsItem
                date={item.created_at}
                title={item.title}
                text={item.text}
              />
            )
          )}
      </Box>
    </Box>
  );
};

export default NewsPage;

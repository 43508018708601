import React from "react";
import { Box, CardContent, Skeleton, Typography } from "@mui/material";
import "./index.scss";
import KeywordsTable from "./KeywordsTable";
import { useGetKeywords } from "../../api/hooks/useGetKeywords";
import LoadingPattern from "../../Components/LoadingPattern";
import AbcIcon from "@mui/icons-material/Abc";
import { useUserDetails } from "../../api/hooks/useUserDetails";
import { calculateAverageRank } from "../../utils/functions";

const Keywords: React.FC = () => {
  const { data, isLoading } = useGetKeywords();
  const { data: userData, isLoading: subLoading } = useUserDetails();

  return (
    <Box sx={{ padding: 2 }} className="mainCharts">
      <Typography component="h1" className="textHeader">
        <AbcIcon className="topIcon" /> מילות מפתח
      </Typography>
      <Box className="keysCards">
        <CardContent className="keywordCard">
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            כמות מילות החיפוש שלך
          </Typography>
          <Typography variant="h5" component="div">
            <Box component="span" className="current">
              {!isLoading && !subLoading ? (
                <>
                  <span style={{ color: "black" }}>
                    {userData.subscription?.keyword_limit || 0} /
                  </span>{" "}
                  {data?.length || 0}
                </>
              ) : (
                <Skeleton />
              )}
            </Box>
          </Typography>
        </CardContent>
        <CardContent className="keywordCard">
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            ממוצע מיקום מילות החיפוש שלך
          </Typography>
          <Typography variant="h5" component="div">
            <Box component="span" className="current">
              {!isLoading && !subLoading && data ? (
                <>{Math.round(calculateAverageRank(data))}</>
              ) : (
                <Skeleton />
              )}
            </Box>
          </Typography>
        </CardContent>
      </Box>
      <Box maxWidth={"xl"}>
        {!isLoading && !subLoading ? (
          <KeywordsTable data={data} subscription={userData?.subscription} />
        ) : (
          <LoadingPattern />
        )}
      </Box>
    </Box>
  );
};

export default Keywords;

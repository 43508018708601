import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ArrowDownward } from "@mui/icons-material";
import "./index.scss";

type Props = {
  rank: number;
  preRank?: number;
};
const Rank: React.FC<Props> = ({ rank, preRank = 999 }) => {
  const increaseRank = rank < preRank;
  const equalRank = rank === preRank;
  const rankIsMinus = rank < 0;
  return (
    <>
      {preRank === -1 && !rankIsMinus ? (
        <Box>
          <Typography>{rank}</Typography>
        </Box>
      ) : !rankIsMinus ? (
        increaseRank ? (
          <Box className="increase">
            <Typography>{rank}</Typography>
            <ArrowUpwardIcon />
          </Box>
        ) : equalRank ? (
          <Box className="reg">
            <Typography>{rank}</Typography>
          </Box>
        ) : (
          <Box className="down">
            <Typography>{rank}</Typography>
            <ArrowDownward />
          </Box>
        )
      ) : (
        <Typography>מיקום לא זמין</Typography>
      )}
    </>
  );
};

export default Rank;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID!,
// };

const firebaseConfig = {
  apiKey: "AIzaSyAVYsp0BOawV8u5i0q_qBZzuwSTz0NpAJ4",
  authDomain: "seowizardtracker.firebaseapp.com",
  projectId: "seowizardtracker",
  storageBucket: "seowizardtracker.appspot.com",
  messagingSenderId: "1021492342790",
  appId: "1:1021492342790:web:73cdc48210978cee345387",
  measurementId: "G-4C0EMWYW5N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, db, analytics };

import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  AlertCircle,
  CheckCircle,
  Info,
  TrendingUp,
  Users,
  ShieldCheck,
  Search,
  Zap,
  ChevronDown,
  ChevronRight,
} from "lucide-react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
} from "recharts";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getLatestReport } from "../../api/db/reports";
import { LighthouseViewerProps } from "../../types/report";
import { Link } from "@mui/material";
import lighthouseLogo from "../../assets/lighthouse.svg";

type ImprovementDetail = {
  type: string;
  items?: Array<Record<string, unknown>>;
};

type Improvement = {
  id: string;
  title: string;
  description: string;
  score: number;
  displayValue: string;
  numericValue: number;
  details?: ImprovementDetail;
};

type Category = {
  title: string;
  score: number;
  improvements: Improvement[];
};

type LighthouseReport = {
  [K in CategoryKey]: Category;
};

const getColorForScore = (score: number) => {
  const percentage = score * 100;
  if (percentage >= 90) return "#22c55e";
  if (percentage >= 50) return "#eab308";
  return "#ef4444";
};

const getCategoryColor = (category: string) => {
  switch (category) {
    case "performance":
      return "#60a5fa";
    case "accessibility":
      return "#10b981";
    case "best-practices":
      return "#fbbf24";
    case "seo":
      return "#a78bfa";
    default:
      return getColorForScore(0);
  }
};

const ScoreGauge: React.FC<{
  score: number;
  size?: "sm" | "lg";
  category?: string;
}> = ({ score, size = "sm", category }) => {
  const percentage = Math.round(score * 100);
  const color = category ? getCategoryColor(category) : getColorForScore(score);

  const dimensions =
    size === "sm" ? { width: 80, height: 80 } : { width: 128, height: 128 };
  const textSize = size === "sm" ? "1.25rem" : "2rem";
  const strokeWidth = size === "sm" ? 8 : 12;

  return (
    <Box sx={{ position: "relative", display: "inline-flex", ...dimensions }}>
      <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 100 100">
        <circle
          strokeWidth={strokeWidth}
          stroke="#e5e7eb"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
        <circle
          strokeWidth={strokeWidth}
          strokeDasharray={Math.PI * 80}
          strokeDashoffset={Math.PI * 80 * (1 - score)}
          strokeLinecap="round"
          stroke={color}
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
          transform="rotate(-90 50 50)"
        />
      </svg>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: textSize, fontWeight: "bold", color: color }}
        >
          {percentage}
        </Typography>
      </Box>
    </Box>
  );
};

const CategoryTab: React.FC<{ category: Category; categoryKey: string }> = ({
  category,
  categoryKey,
}) => {
  const color = getCategoryColor(categoryKey);
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const toggleItem = (improvementId: string) => {
    setExpandedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(improvementId)) {
        newSet.delete(improvementId);
      } else {
        newSet.add(improvementId);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5" component="h2" sx={{ color: color }}>
          {category.title}
        </Typography>
        <ScoreGauge score={category.score} category={categoryKey} />
      </Box>
      <Box sx={{ maxHeight: 400, overflow: "auto" }}>
        {category.improvements && category.improvements.length > 0 ? (
          category.improvements.map((improvement) => (
            <motion.div
              key={improvement.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleItem(improvement.id)}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {improvement.title}
                  </Typography>
                  {expandedItems.has(improvement.id) ? (
                    <ChevronDown size={20} />
                  ) : (
                    <ChevronRight size={20} />
                  )}
                </Box>

                <motion.div
                  initial={false}
                  animate={{
                    height: expandedItems.has(improvement.id) ? "auto" : 0,
                    opacity: expandedItems.has(improvement.id) ? 1 : 0,
                  }}
                  style={{
                    overflow: "hidden",
                    marginTop: expandedItems.has(improvement.id) ? "12px" : 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paragraph
                    sx={{ mb: improvement.displayValue ? 2 : 0 }}
                  >
                    {improvement.description}
                  </Typography>

                  {improvement.displayValue && (
                    <Chip
                      label={improvement.displayValue}
                      sx={{ bgcolor: color, color: "white" }}
                      size="small"
                    />
                  )}
                </motion.div>
              </Paper>
            </motion.div>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 300,
              textAlign: "center",
            }}
          >
            <Box sx={{ mb: 2, color: "success.main" }}>
              <CheckCircle size={48} />
            </Box>
            <Typography variant="h6" sx={{ color: "success.main" }}>
              אין המלצות לשיפור
            </Typography>
            <Typography variant="body2" color="text.secondary">
              כל הפרמטרים בקטגוריה זו תקינים
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CategoryIcon: React.FC<{ category: string }> = ({ category }) => {
  const iconProps = { fontSize: "small" };
  switch (category) {
    case "performance":
      return <Zap {...iconProps} />;
    case "accessibility":
      return <Users {...iconProps} />;
    case "best-practices":
      return <ShieldCheck {...iconProps} />;
    case "seo":
      return <Search {...iconProps} />;
    default:
      return null;
  }
};

const ScoreCard: React.FC<{
  category: string;
  score: number;
  title: string;
}> = ({ category, score, title }) => {
  const color = getCategoryColor(category);

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardHeader
          sx={{ bgcolor: color, color: "white" }}
          title={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CategoryIcon category={category} />
              <Typography variant="h6" sx={{ ml: 1 }}>
                {title}
              </Typography>
            </Box>
          }
        />
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ScoreGauge score={score} category={category} />
        </CardContent>
      </Card>
    </motion.div>
  );
};

const RadarScoreChart: React.FC<{ data: LighthouseReport }> = ({ data }) => {
  const chartData = Object.entries(data).map(([key, value]) => ({
    subject: value.title,
    score: Math.round(value.score * 100),
    fullMark: 100,
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
        <PolarGrid stroke="#e0e0e0" />
        <PolarAngleAxis
          dataKey="subject"
          tick={{ fill: "#757575", fontSize: 12 }}
        />
        <PolarRadiusAxis
          angle={30}
          domain={[0, 100]}
          tick={{ fill: "#9e9e9e", fontSize: 10 }}
        />
        <Radar
          name="ציון"
          dataKey="score"
          stroke="#3b82f6"
          fill="#3b82f6"
          fillOpacity={0.4}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

type CategoryKey = "performance" | "accessibility" | "best-practices" | "seo";
const CATEGORY_ORDER: CategoryKey[] = [
  "performance",
  "accessibility",
  "best-practices",
  "seo",
];

export default function LighthouseViewer({ siteId }: LighthouseViewerProps) {
  const [LIGHTHOUSE_DATA, setReportData] = useState<LighthouseReport | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [activeTab, setActiveTab] = React.useState<CategoryKey>("performance");
  useEffect(() => {
    const fetchReport = async () => {
      try {
        setLoading(true);
        const result = await getLatestReport(siteId);
        if (result && result.report) {
          setReportData(result.report as LighthouseReport);
        }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to fetch report")
        );
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [siteId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography>טוען דו"ח</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  if (!LIGHTHOUSE_DATA) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography>אין דו"ח זמין כעת</Typography>
      </Box>
    );
  }

  const getSortedEntries = (
    data: LighthouseReport
  ): [CategoryKey, Category][] => {
    return CATEGORY_ORDER.filter((key) => key in data).map((key) => [
      key,
      data[key],
    ]);
  };
  const sortedEntries = getSortedEntries(LIGHTHOUSE_DATA);
  const overallScore =
    Object.values(LIGHTHOUSE_DATA).reduce(
      (sum, category) => sum + category.score,
      0
    ) / Object.keys(LIGHTHOUSE_DATA).length;
  const overallScorePercentage = Math.round(overallScore * 100);

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", p: 3 }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{ mb: 4 }}
      >
        דוח נתונים על האתר
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <motion.div
            whileHover={{ scale: 1.01 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{ height: "100%" }}
          >
            <Card sx={{ height: "100%" }}>
              <CardHeader
                title="ביצועים כוללים"
                subheader="הציון הכולל לאתר שלך"
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ScoreGauge score={overallScore} size="lg" />
                <Typography variant="h4" sx={{ mt: 2 }}>
                  {overallScorePercentage}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {overallScorePercentage >= 90
                    ? "מצוין"
                    : overallScorePercentage >= 70
                    ? "טוב"
                    : overallScorePercentage >= 50
                    ? "דורש שיפור"
                    : "חלש"}
                </Typography>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <motion.div
            whileHover={{ scale: 1.01 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{ height: "100%" }}
          >
            <Card sx={{ height: "100%" }}>
              <CardHeader
                title="פירוט ביצועים"
                subheader="תצוגה מפורטת של כל קטגוריית ביצועים"
              />
              <CardContent>
                <RadarScoreChart data={LIGHTHOUSE_DATA} />
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        {sortedEntries.map(([key, category]) => (
          <Grid item xs={12} sm={6} md={3} key={key}>
            <ScoreCard
              category={key}
              score={category.score}
              title={category.title}
            />
          </Grid>
        ))}
      </Grid>

      <Card>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
        >
          {sortedEntries?.map(([key, category]) => (
            <Tab
              key={key}
              value={key}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CategoryIcon category={key} />
                  <Box sx={{ ml: 1 }}>{category.title}</Box>
                </Box>
              }
            />
          ))}
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {sortedEntries.map(
            ([key, category]) =>
              activeTab === key && (
                <CategoryTab key={key} category={category} categoryKey={key} />
              )
          )}
        </Box>
      </Card>

      <motion.div
        whileHover={{ scale: 1.01 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{ marginTop: "24px" }}
      >
        <Card
          sx={{
            bgcolor: "background.paper",
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
          }}
        >
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ mr: 1, color: "primary.main" }}>
                  <Info size={24} />
                </Box>
                <Typography variant="h5" sx={{ color: "primary.main" }}>
                  טיפים מהירים לשיפור
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <List>
              <ListItem
                sx={{ bgcolor: "background.paper", mb: 2, borderRadius: 1 }}
              >
                <ListItemIcon>
                  <CheckCircle color="success" />
                </ListItemIcon>
                <ListItemText primary="אופטימיזציה של תמונות ודחיסת נכסים לשיפור מהירות הטעינה." />
              </ListItem>
              <ListItem
                sx={{ bgcolor: "background.paper", mb: 2, borderRadius: 1 }}
              >
                <ListItemIcon>
                  <CheckCircle color="success" />
                </ListItemIcon>
                <ListItemText primary="יישום אסטרטגיות מטמון להפחתת העומס על השרת ושיפור זמני התגובה." />
              </ListItem>
              <ListItem sx={{ bgcolor: "background.paper", borderRadius: 1 }}>
                <ListItemIcon>
                  <AlertCircle color="warning" />
                </ListItemIcon>
                <ListItemText primary="עדכון ותחזוקה שוטפים של האתר שלך להבטחת ביצועים אופטימליים." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </motion.div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
        dir="ltr"
        mt={1}
      >
        <img
          src={lighthouseLogo}
          alt="Lighthouse logo"
          width={20}
          height={20}
        />
        <Typography variant="caption" color="text.secondary">
          Powered by{" "}
          <Link
            href="https://developer.chrome.com/docs/lighthouse/overview/"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            underline="hover"
          >
            Lighthouse
          </Link>{" "}
          (12.1.1)
        </Typography>
      </Box>
    </Box>
  );
}

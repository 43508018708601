import axios from "axios";
import { useQuery } from "react-query";
import { baseURL, queryKeys } from "../consts";
import { getToken } from "../authUtils";

const fetchKeywords = async () => {
  const token = await getToken();
  const data = await axios.get(`${baseURL}/users/keywords`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data?.data;
};

export const useGetKeywords = () => {
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.KEYWORDS],
    queryFn: () => fetchKeywords(),
  });

  return { data, isLoading };
};

import { ReactElement, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Chip,
  Tooltip,
  IconButton,
  Divider,
  Collapse,
} from "@mui/material";
import {
  Shield,
  Calendar,
  HelpCircle,
  Lock,
  Globe,
  AlertTriangle,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { SSLCheck } from "./types";

interface SSLStatusProps {
  sslCheck: SSLCheck | null;
  isLoading?: boolean;
}

const SSL_EXPLANATION = `
תעודת SSL היא כמו "מערכת אבטחה דיגיטלית" לאתר שלך.
היא מגנה על המידע של הלקוחות שלך ועל האתר שלך, ומראה ללקוחות שהאתר שלך אמין ובטוח.

למה זה חשוב?
- גוגל מעדיף אתרים עם SSL ומדרג אותם גבוה יותר
- לקוחות רואים סימן 🔒 ליד כתובת האתר
- מונע התראות "אתר לא בטוח" בדפדפן
- חובה לאתרי מסחר ותשלומים

תעודה לא תקפה או פגה יכולה לגרום ללקוחות לראות אזהרות ולהימנע מכניסה לאתר.
`;

const SSLStatus = ({
  sslCheck,
  isLoading,
}: SSLStatusProps): ReactElement | null => {
  const [expanded, setExpanded] = useState(false);

  if (isLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          dir="rtl"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Shield size={20} color="gray" />
            <Typography>סטטוס SSL</Typography>
          </Box>
          <Chip label="טוען..." size="small" />
        </Box>
      </Paper>
    );
  }

  if (!sslCheck) return null;

  const { check } = sslCheck;
  const needsRenewal = check.daysRemaining <= 30;
  const lastChecked = new Date(sslCheck.createdAt).toLocaleDateString("he-IL");

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={2} dir="rtl">
        {/* Main Status - Always Visible */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Shield
              size={20}
              color={
                check.isValid ? (needsRenewal ? "orange" : "green") : "red"
              }
            />
            <Typography>סטטוס SSL</Typography>
            <Tooltip
              title={
                <Typography
                  style={{ whiteSpace: "pre-line", textAlign: "right" }}
                >
                  {SSL_EXPLANATION}
                </Typography>
              }
              arrow
              onClick={(e) => e.stopPropagation()} // Prevent collapse toggle when clicking tooltip
            >
              <IconButton size="small" color="primary">
                <HelpCircle size={16} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Chip
              label={check.isValid ? "תקין" : "לא תקין"}
              color={check.isValid ? "success" : "error"}
              size="small"
            />
            {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </Box>
        </Box>

        {/* Expandable Details */}
        <Collapse in={expanded}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Divider />

            {/* SSL Details */}
            <Box display="flex" flexDirection="column" gap={1}>
              {/* Provider Info */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Lock size={16} />
                  <Typography>ספק האבטחה</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {check.issuer || "Let's Encrypt"}
                </Typography>
              </Box>

              {/* Domain Coverage */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Globe size={16} />
                  <Typography>כיסוי דומיינים</Typography>
                </Box>
                <Chip
                  label={
                    check.domains?.isWildcard
                      ? "כל תת-הדומיינים"
                      : "דומיין ראשי"
                  }
                  size="small"
                  variant="outlined"
                />
              </Box>

              {/* Expiry Info */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Calendar size={16} />
                  <Typography>תוקף התעודה</Typography>
                </Box>
                <Typography>
                  {new Date(check.expiryDate).toLocaleDateString("he-IL")}
                </Typography>
              </Box>
            </Box>

            {/* Warning Messages */}
            {(needsRenewal || !check.isValid) && (
              <Box
                sx={{
                  bgcolor: "warning.light",
                  p: 1,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AlertTriangle size={16} color="warning" />
                <Typography color="warning.dark" variant="body2">
                  {!check.isValid
                    ? "התעודה אינה תקפה - הלקוחות שלך רואים אזהרת אבטחה"
                    : "יש לחדש את תעודת האבטחה בקרוב כדי למנוע בעיות גישה לאתר"}
                </Typography>
              </Box>
            )}

            {/* Last Check Info */}
            <Typography variant="caption" color="text.secondary" align="left">
              נבדק לאחרונה: {lastChecked}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default SSLStatus;

import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DomainReport } from "../../../Components/Report/domainReport/types";
import { getLatestDomainReport } from "../../../api/db/domain";
import DomainStatus from "../../../Components/Report/domainReport/DomainStatus";

interface DomainContainerProps {
  siteId: string;
}

export default function DomainContainer({ siteId }: DomainContainerProps) {
  const [domainReport, setDomainReport] = useState<DomainReport | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDomainReport = async () => {
      try {
        setIsLoading(true);
        const data = await getLatestDomainReport(siteId);
        setDomainReport(data);
      } catch (error) {
        console.error("Failed to fetch Domain data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDomainReport();
  }, [siteId]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return <DomainStatus domainReport={domainReport} />;
}

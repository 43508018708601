const websiteRegex = /^(https?:\/\/)/;
const israeliPhoneRegex = /^(\+972|0)([23489]|5[0-9])[0-9]{7}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateWebsite = (value: string, setErrors: any) => {
  if (!websiteRegex.test(value)) {
    setErrors((prev: any) => ({
      ...prev,
      website: "כתובת אתר חייבת להכיל https",
    }));
  } else {
    setErrors((prev: any) => ({ ...prev, website: "" }));
  }
};

export const validatePhone = (value: string, setErrors: any) => {
  if (!israeliPhoneRegex.test(value)) {
    setErrors((prev: any) => ({ ...prev, phone: "מספר טלפון לא תקין" }));
  } else {
    setErrors((prev: any) => ({ ...prev, phone: "" }));
  }
};

export const validateEmail = (value: string, setErrors: any) => {
  if (!emailRegex.test(value)) {
    setErrors((prev: any) => ({ ...prev, email: "האימייל שהוזן לא תקין" }));
  } else {
    setErrors((prev: any) => ({ ...prev, email: "" }));
  }
};

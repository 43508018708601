import { ReactElement, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Chip,
  Tooltip,
  IconButton,
  Divider,
  Collapse,
} from "@mui/material";
import {
  Globe,
  Calendar,
  HelpCircle,
  AlertTriangle,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { DomainReport } from "./types";

interface DomainStatusProps {
  domainReport: DomainReport | null;
  isLoading?: boolean;
}

const DOMAIN_EXPLANATION = `
דו"ח דומיין מציג את המידע הבסיסי של הדומיין שלך כולל תאריך יצירה, תאריך עדכון ותוקף.
תאריכים אלו חשובים לניהול התקינות והשימושיות של הדומיין שלך.
`;

const DomainStatus = ({
  domainReport,
  isLoading,
}: DomainStatusProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);

  if (isLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          dir="rtl"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Globe size={20} color="gray" />
            <Typography>סטטוס דומיין</Typography>
          </Box>
          <Chip label="טוען..." size="small" />
        </Box>
      </Paper>
    );
  }

  if (!domainReport || !domainReport.check) {
    return (
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={2} dir="rtl">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={1}>
              <Globe size={20} color="gray" />
              <Typography>סטטוס דומיין</Typography>
            </Box>
            <Chip label="לא זמין" size="small" color="default" />
          </Box>
          <Typography color="text.secondary">
            אין מידע על דומיין זמין כרגע.
          </Typography>
        </Box>
      </Paper>
    );
  }

  const { creation_date, expiration_date, updated_date } = domainReport.check;
  const currentDate = Date.now();
  const expirationDate = new Date(expiration_date).getTime();
  const isExpired = expirationDate < currentDate;
  const isExpiringSoon =
    expirationDate - currentDate <= 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

  const chipLabel = isExpired
    ? "פג תוקף"
    : isExpiringSoon
    ? "קרוב לפקיעה"
    : "פעיל";

  const chipColor = isExpired
    ? "error" // Red
    : isExpiringSoon
    ? "warning" // Yellow
    : "success"; // Green

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={2} dir="rtl">
        {/* Main Status - Always Visible */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Globe
              size={20}
              color={isExpired ? "red" : isExpiringSoon ? "orange" : "green"}
            />
            <Typography>סטטוס דומיין</Typography>
            <Tooltip
              title={
                <Typography
                  style={{ whiteSpace: "pre-line", textAlign: "right" }}
                >
                  {DOMAIN_EXPLANATION}
                </Typography>
              }
              arrow
              onClick={(e) => e.stopPropagation()} // Prevent collapse toggle when clicking tooltip
            >
              <IconButton size="small" color="primary">
                <HelpCircle size={16} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Chip label={chipLabel} color={chipColor} size="small" />
            {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </Box>
        </Box>

        {/* Expandable Details */}
        <Collapse in={expanded}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Divider />

            {/* Domain Details */}
            <Box display="flex" flexDirection="column" gap={1}>
              {/* Creation Date */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Calendar size={16} />
                  <Typography>תאריך יצירה</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {new Date(creation_date).toLocaleDateString("he-IL")}
                </Typography>
              </Box>

              {/* Updated Date */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Calendar size={16} />
                  <Typography>תאריך עדכון</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {new Date(updated_date).toLocaleDateString("he-IL")}
                </Typography>
              </Box>

              {/* Expiration Date */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Calendar size={16} />
                  <Typography>תאריך תפוגה</Typography>
                </Box>
                <Typography>
                  {new Date(expiration_date).toLocaleDateString("he-IL")}
                </Typography>
              </Box>
            </Box>

            {/* Warning Messages */}
            {(isExpired || isExpiringSoon) && (
              <Box
                sx={{
                  bgcolor: "warning.light",
                  p: 1,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AlertTriangle size={16} color="warning" />
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                >
                  {isExpired
                    ? "התוקף פג - יש לחדש את הדומיין מיידית"
                    : "יש לחדש את הדומיין בקרוב כדי למנוע איבוד שליטה עליו"}
                </Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default DomainStatus;

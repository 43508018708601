import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import "./index.scss";
import { formatDate } from "../../../utils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

type Props = {
  date: string;
  title: string;
  text: string;
};
const NewsItem: React.FC<Props> = ({ date, title, text }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography className="title">
          {formatDate(date)} - <span className="titleText">{title}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default NewsItem;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./index.scss";
import AddKeywordDialog from "./AddKeywordDialog";
import Rank from "../../../Components/Rank";
import { useDeleteKeyword } from "../../../api/hooks/useDeleteKeyword";
import { queryKeys } from "../../../api/consts";
import { useQueryClient } from "react-query";
import { formatDate } from "../../../utils";
import InfoIcon from "@mui/icons-material/Info";

type Props = {
  data: any;
  subscription: any;
};
const KeywordsTable: React.FC<Props> = ({ data, subscription }) => {
  const queryClient = useQueryClient();

  const onSuccessHandler = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.KEYWORDS] });
  };
  const mutation = useDeleteKeyword(onSuccessHandler);

  const [open, setOpen] = useState(false);
  const [keywordAdded, setKeywordsAdded] = useState();
  const keysLimit = data.length >= subscription.keyword_limit || false;
  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Tooltip
        title={
          keysLimit ? "לא ניתן להוסיף מעל 5 מילות חיפוש" : "הוספת מילת חיפוש"
        }
      >
        <Button
          variant="outlined"
          disabled={keysLimit}
          onClick={() => setOpen(true)}
        >
          הוספת מילת מפתח
        </Button>
      </Tooltip>
      {keysLimit && (
        <div>
          <Typography variant="overline">
            החבילה שלך תומכת עד <strong>{subscription.keyword_limit}</strong>{" "}
            מילות חיפוש
          </Typography>
        </div>
      )}
      <AddKeywordDialog
        openDialog={open}
        setDialogOpen={setOpen}
        setKeywordsAdded={setKeywordsAdded}
      />
      <TableContainer component={Paper} sx={{ marginTop: "1rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="header">
              <TableCell>מילת החיפוש</TableCell>
              <TableCell align="right" className="right">
                תאריך הוספה{" "}
                <Tooltip title="תאריך שבו הוספתם את המילת חיפוש">
                  <InfoIcon />
                </Tooltip>
              </TableCell>
              <TableCell align="center" className="right">
                מיקום בגוגל{" "}
                <Tooltip title="המיקום הנוכחי שלכם בגוגל">
                  <InfoIcon />
                </Tooltip>
              </TableCell>
              <TableCell align="center" className="right">
                מיקום קודם{" "}
                <Tooltip title="המיקום הקודם שבו הופעתם בגוגל">
                  <InfoIcon />
                </Tooltip>
              </TableCell>
              <TableCell align="right">אפשרויות</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((keyword: any) => (
                <TableRow
                  key={keyword.keyword}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b>{keyword.keyword}</b>
                  </TableCell>
                  <TableCell align="right">
                    {formatDate(keyword?.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {keyword.urlRanks?.length > 0 ? (
                      <Rank
                        rank={keyword?.urlRanks?.[0]?.rank}
                        preRank={keyword?.urlRanks?.[0]?.previousRank}
                      />
                    ) : (
                      "לא זמין"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {keyword?.urlRanks?.length > 0
                      ? keyword?.urlRanks?.[0]?.previousRank === -1
                        ? "לא זמין"
                        : keyword?.urlRanks?.[0]?.previousRank
                      : "לא זמין"}
                  </TableCell>

                  <TableCell align="right">
                    {!mutation.isLoading ? (
                      <Tooltip title="מחיקת מילת חיפוש">
                        <DeleteIcon
                          onClick={() => {
                            mutation.mutate(keyword?.id);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <CircularProgress style={{ width: 15, height: 15 }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KeywordsTable;

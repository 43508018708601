import React, { useState, useMemo, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
  ReferenceArea,
} from "recharts";
import "./index.scss";

interface DataPoint {
  name: string;
  [key: string]: string | number;
}

interface Props {
  data: DataPoint[];
  text: string;
}

interface VisibleLines {
  [key: string]: boolean;
}

const colors = [
  "#0bb4ff",
  "#e60049",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

const colorsFade = [
  "#e6004994",
  "#0bb4ff94",
  "#50e991cf",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

const KeywordsChart: React.FC<Props> = ({ data = [], text = "" }) => {
  const [leftZoom, setLeftZoom] = useState<string | null>(null);
  const [rightZoom, setRightZoom] = useState<string | null>(null);
  const [zoomedData, setZoomedData] = useState<DataPoint[]>(data);
  const [isZooming, setIsZooming] = useState(false);
  const [visibleLines, setVisibleLines] = useState<VisibleLines>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const keys = useMemo(() => {
    if (!data.length) return [];
    const norData = data.map((obj: DataPoint) =>
      Object.keys(obj).filter((key) => key !== "name")
    );
    return Array.from(new Set(norData.flat()));
  }, [data]);

  useEffect(() => {
    if (keys.length > 0) {
      const initialVisibility = keys.reduce(
        (acc: VisibleLines, key: string) => {
          acc[key] = true;
          return acc;
        },
        {}
      );
      setVisibleLines(initialVisibility);
    }
  }, [keys]);

  useEffect(() => {
    setZoomedData(data);
  }, [data]);

  const yAxisTicks = useMemo(() => {
    if (!zoomedData.length) return [0, 100];

    const values: number[] = [];
    zoomedData.forEach((item: DataPoint) => {
      keys.forEach((key: string) => {
        if (visibleLines[key]) {
          const value = Number(item[key]);
          if (!isNaN(value)) {
            values.push(Math.round(value));
          }
        }
      });
    });

    if (!values.length) return [0, 100];

    const min = Math.min(...values);
    const max = Math.max(...values);
    return [Math.max(0, min - 5), Math.min(100, max + 5)];
  }, [zoomedData, keys, visibleLines]);

  const xAxisInterval = useMemo(() => {
    const dataLength = zoomedData.length;
    if (isMobile) {
      if (dataLength <= 5) return 0;
      if (dataLength <= 10) return 1;
      if (dataLength <= 20) return 2;
      return Math.floor(dataLength / 5);
    }
    if (dataLength <= 7) return 0;
    if (dataLength <= 14) return 1;
    if (dataLength <= 30) return 2;
    return Math.floor(dataLength / 10);
  }, [zoomedData, isMobile]);

  // Mouse event handlers remain the same
  const handleMouseDown = (e: any) => {
    if (!e) return;
    setIsZooming(true);
    setLeftZoom(e.activeLabel);
  };

  const handleMouseMove = (e: any) => {
    if (!isZooming || !e) return;
    setRightZoom(e.activeLabel);
  };

  const handleMouseUp = () => {
    if (!isZooming) return;
    setIsZooming(false);

    if (!leftZoom || !rightZoom || !data) {
      setZoomedData(data);
      return;
    }

    const startIndex = data.findIndex((item) => item.name === leftZoom);
    const endIndex = data.findIndex((item) => item.name === rightZoom);

    const start = Math.min(startIndex, endIndex);
    const end = Math.max(startIndex, endIndex);

    if (start === -1 || end === -1) {
      setZoomedData(data);
      return;
    }

    setZoomedData(data.slice(start, end + 1));
    setLeftZoom(null);
    setRightZoom(null);
  };

  const handleResetZoom = () => {
    setZoomedData(data);
  };

  const handleLegendClick = (dataKey: string) => {
    setVisibleLines((prev) => ({
      ...prev,
      [dataKey]: !prev[dataKey],
    }));
  };

  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          height: { xs: "200px", sm: "500px", md: "300px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.paper",
          borderRadius: 1,
          p: { xs: 1, sm: 2 },
        }}
      >
        <Typography variant="h6" color="text.secondary">
          אין נתונים להצגה
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "95%",
        height: { xs: "250px", sm: "250px", md: "250px" },
        p: { xs: 1, sm: 2 },
        bgcolor: "background.paper",
        borderRadius: 1,
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "stretch", sm: "center" },
          mb: 2,
          gap: 2,
          px: 3,
        }}
      >
        <Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            {text}
          </Typography>
        </Box>
        <Box sx={{ flex: "0 0 auto" }}>
          <Button onClick={handleResetZoom} variant="outlined">
            אפס זום
          </Button>
        </Box>
      </Box>

      <Box sx={{ height: "1px", bgcolor: "divider", mb: 2 }} />

      <Box sx={{ height: "80%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={zoomedData}
            margin={{
              top: 5,
              right: isMobile ? 10 : 30,
              left: isMobile ? 5 : 20,
              bottom: 5,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              allowDataOverflow={true}
              interval={xAxisInterval}
              height={30}
              tick={{ fontSize: isMobile ? 10 : 12 }}
              angle={isMobile ? -45 : 0}
              textAnchor={isMobile ? "end" : "middle"}
            />
            <YAxis
              type="number"
              reversed={true}
              domain={[Math.min(...yAxisTicks), Math.max(...yAxisTicks)]}
              allowDataOverflow={true}
              padding={{ top: 20, bottom: 20 }}
              tick={{ fontSize: isMobile ? 10 : 12 }}
              width={isMobile ? 25 : 35}
            />
            <Tooltip contentStyle={{ fontSize: isMobile ? 12 : 14 }} />
            <Legend
              onClick={(data) => handleLegendClick(data.value)}
              formatter={(value, entry, index) => (
                <span
                  style={{
                    marginRight: "15px",
                    cursor: "pointer",
                    color: visibleLines[value]
                      ? colors[index % colors.length]
                      : "grey",
                    fontWeight: visibleLines[value] ? "bold" : "normal",
                    fontSize: isMobile ? 12 : 14,
                  }}
                >
                  {value}
                </span>
              )}
              wrapperStyle={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            />
            <Brush
              dataKey="name"
              height={30}
              stroke="#0bb4ff"
              travellerWidth={isMobile ? 7 : 10}
            />
            {keys.map((key, index) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={colors[index % colors.length]}
                fill={colorsFade[index % colorsFade.length]}
                strokeWidth={isMobile ? 1 : 2}
                dot={false}
                activeDot={{ r: isMobile ? 4 : 6 }}
                hide={!visibleLines[key]}
              />
            ))}
            {leftZoom && rightZoom && (
              <ReferenceArea
                x1={leftZoom}
                x2={rightZoom}
                strokeOpacity={0.3}
                fill="#0bb4ff"
                fillOpacity={0.1}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default KeywordsChart;

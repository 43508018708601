import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../Pages/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../Pages/Dashboard";
import Keywords from "../Pages/Keywords";
import UnProtectedRoute from "./UnProtectedRoute";
import RegisterPage from "../Pages/RegisterPage";
import StatisticsPage from "../Pages/Dashboard/StatisticsPage";
import Competitors from "../Pages/Competitor";
import NewsPage from "../Pages/News";
import ReportsPage from "../Pages/Dashboard/ReportsPage";
import LighthouseViewer from "../Components/Report";
import ReportPage from "../Pages/Report";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="login"
          element={
            <UnProtectedRoute>
              <Login />{" "}
            </UnProtectedRoute>
          }
        />
        <Route
          path="register"
          element={
            <UnProtectedRoute>
              <RegisterPage />{" "}
            </UnProtectedRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route path="keys" element={<Keywords />} />
          <Route path="comps" element={<Competitors />} />
          {/* <Route path="reports" element={<ReportsPage />} /> */}
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="reports" element={<ReportPage />} />
          <Route path="news" element={<NewsPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { getLatestSSLCheck } from "../../../api/db/ssl";
import { SSLCheck } from "../../../Components/Report/sslReport/types";
import SSLStatus from "../../../Components/Report/sslReport/SSLStatus";

interface SSLContainerProps {
  siteId: string;
}

export default function SSLContainer({ siteId }: SSLContainerProps) {
  const [sslCheck, setSSLCheck] = useState<SSLCheck | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSSL = async () => {
      try {
        setIsLoading(true);
        const data = await getLatestSSLCheck(siteId);
        setSSLCheck(data);
      } catch (error) {
        console.error("Failed to fetch SSL data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSSL();
  }, [siteId]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return <SSLStatus sslCheck={sslCheck} />;
}

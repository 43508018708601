import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
} from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import useCreateUser from "../../api/hooks/useCreateUser";
import { useAtom, useSetAtom } from "jotai";
import { createUserDetails } from "../../globalAtom/createUserAtom";
import logo from "../../assets/logo.png";
import { validateEmail, validatePhone, validateWebsite } from "./validations";

const RegisterPage = () => {
  const mutation = useCreateUser();
  const [userDetails, setUser] = useAtom(createUserDetails);
  const [errors, setError] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    siteUrl: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const isPopulated =
      errors["email" as keyof typeof errors] !== "" ||
      errors["phone" as keyof typeof errors] !== "" ||
      errors["website" as keyof typeof errors] !== "";
    if (isPopulated) return;
    if (userDetails) {
      mutation.mutate({ email: formData.email, password: formData.password });
      setUser(undefined);
    }
  }, [userDetails]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateWebsite(formData.siteUrl, setError);
    validatePhone(formData.phoneNumber, setError);
    validateEmail(formData.email, setError);

    setUser(formData);
  };

  return (
    <Container maxWidth="sm" className="container">
      <Box className="main">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} width="120" alt="logo" />

          <Typography variant="h6">
            ברוכים הבאים ל - <strong>WeSeo</strong>
          </Typography>
          <Typography>על מנת להשתמש במערכת דבר ראשון יש להרשם.</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="שם מלא"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="אימייל"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={(errors as any)?.email}
                  required
                  helperText={(errors as any)?.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="סיסמא"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="מספר פלאפון"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  error={(errors as any)?.phone}
                  required
                  helperText={(errors as any)?.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="קישור לאתר"
                  name="siteUrl"
                  type="text"
                  value={formData.siteUrl}
                  onChange={handleChange}
                  error={(errors as any)?.website}
                  required
                  helperText={(errors as any)?.website}
                />
                {/* {(errors as any)?.website && (
                  <p style={{ color: "red" }}>{(errors as any)?.website}</p>
                )} */}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  type="submit"
                >
                  הרשמה
                </Button>
              </Grid>
            </Grid>
          </form>
          <small style={{ marginTop: "1rem" }}>
            <strong>
              {" "}
              <Link to="/login">נרשמתם בעבר ? עברו להתחברות</Link>{" "}
            </strong>
          </small>
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterPage;

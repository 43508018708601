import React from "react";
import "./index.scss";
import { Box, Skeleton } from "@mui/material";

const LoadingPattern: React.FC = () => {
  return (
    <>
      <Box className="loadingPattern">
        {[...new Array(5)].map(() => (
          <Skeleton variant="rounded" width={"80%"} height={60} />
        ))}
      </Box>
    </>
  );
};

export default LoadingPattern;

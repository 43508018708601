import React from "react";
import "./index.scss";

import { Box, CardContent, Skeleton, Typography } from "@mui/material";

type Props = {
  text: string;
  isLoading?: boolean;
  data: any;
};

const DataCard: React.FC<Props> = ({ text, isLoading = false, data }) => {
  return (
    <CardContent className="keywordCard">
      <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
        {text}
      </Typography>
      <Typography variant="h5" component="div">
        <Box component="span" className="current">
          {!isLoading ? data : <Skeleton />}
        </Box>
      </Typography>
    </CardContent>
  );
};

export default DataCard;

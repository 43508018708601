export function calculateAverageRank(data: any) {
  let totalRank = 0;
  let rankCount = 0;
  if (data) {
    data?.forEach((item: any) => {
      item.urlRanks?.forEach((rankObj: any) => {
        totalRank += rankObj.rank;
        rankCount += 1;
      });
    });
  }

  return rankCount > 0 ? totalRank / rankCount : 0;
}

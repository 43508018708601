import React from "react";
import "./index.scss";

import { Box } from "@mui/material";

import { Typography } from "@mui/material";
import { useGetHistory } from "../../../api/hooks/useGetHistory";
import MainCharts from "./mainCharts";
import LoadingPattern from "../../../Components/LoadingPattern";
import Error from "../../../Components/Error";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useUserDetails } from "../../../api/hooks/useUserDetails";
const StatisticsPage: React.FC = () => {
  const { data: d, isLoading, error } = useGetHistory();
  const { data: userData, isLoading: subLoading } = useUserDetails();
  return (
    <>
      <Box className="mainCharts">
        <Typography component="h1" className="textHeader">
          <QueryStatsIcon className="topIcon" /> סטטיסטיקות
        </Typography>

        {d && (
          <MainCharts
            d={d}
            isLoading={isLoading}
            userDataSubscription={userData?.subscription}
          />
        )}
        {isLoading && subLoading && <LoadingPattern />}
        {error && <Error />}
      </Box>
    </>
  );
};

export default StatisticsPage;

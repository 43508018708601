import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import "./index.scss";
import { useAddKeyword } from "../../../../api/hooks/useAddKeyword";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../../../api/consts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { useAddKeyword } from "../../../../api/api";

type Props = {
  openDialog: boolean;
  setDialogOpen: any;
  setKeywordsAdded: any;
};

const AddKeywordDialog: React.FC<Props> = ({
  openDialog,
  setDialogOpen,
  setKeywordsAdded,
}) => {
  const MySwal = withReactContent(Swal);

  const queryClient = useQueryClient();

  const onSuccessHandler = () => {
    queryClient.refetchQueries({
      queryKey: [queryKeys.KEYWORDS],
    });
    queryClient.refetchQueries({
      queryKey: [queryKeys.HISTORY],
    });
    setDialogOpen(false);
  };

  const onErrorHandler = () => {
    MySwal.fire({
      title: "שגיאה",
      text: "קרתה שגיאת מערכת 5432 , נא לנסות שוב או לפנות לתמיכה טכנית",
      icon: "error",
      confirmButtonText: "בוצע",
    });
  };

  const { mutate, isLoading } = useAddKeyword(onSuccessHandler, onErrorHandler);
  const [text, setText] = useState("");

  return (
    <Dialog
      open={openDialog}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">הוספת מילת חיפוש</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography>המערכת מוסיפה את מילת החיפוש</Typography>
          </Box>
        ) : (
          <DialogContentText id="alert-dialog-description">
            הזן את מילת החיפוש ולחץ על הוספה
            <TextField
              label="מילת חיפוש"
              type="text"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? null : (
          <>
            <Button onClick={() => setDialogOpen(false)}>ביטול</Button>
            <Button
              onClick={() => {
                mutate(text);
                setKeywordsAdded(text);
              }}
              autoFocus
            >
              הוספה
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddKeywordDialog;

import axios from "axios";
import { useMutation } from "react-query";
import { baseURL } from "../consts";
import { getToken } from "../authUtils";

const fetchComps = async (compId: string) => {
  const token = await getToken();
  const data = await axios.post(
    `${baseURL}/users/competitor-sites/delete`,
    {
      competitorSiteUrl: compId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data?.data;
};

export const useDeleteComps = (onSuccess: any, onError?: any) => {
  const mutation = useMutation({
    mutationFn: (compId: string) => fetchComps(compId),
    onSuccess: onSuccess,
  });

  return mutation;
};

import React from "react";
import { Box, Typography } from "@mui/material";
import LoadingPattern from "../../Components/LoadingPattern";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LighthouseViewer from "../../Components/Report";
import { useUserDetails } from "../../api/hooks/useUserDetails";
import SSLContainer from "./SSL";
import DomainContainer from "./Domain";

const ReportPage: React.FC = () => {
  const { data, isLoading } = useUserDetails();
  return (
    <Box sx={{ padding: 2 }} className="mainCharts">
      <Typography component="h1" className="textHeader">
        <NewspaperIcon className="topIcon" /> דוחות
      </Typography>

      {isLoading ? (
        <LoadingPattern />
      ) : data?.parsedSite ? (
        <>
        <Box sx={{ mb: 1 }}>
          <SSLContainer siteId={data.parsedSite[0]} />
        </Box>
        <Box sx={{ mb: 1 }}>
          <DomainContainer siteId={data.parsedSite[0]} />
        </Box>
        <Box>
          <LighthouseViewer siteId={data.parsedSite[0]} />
        </Box>
        </>
      ) : (
        <Typography>אין דוח זמין</Typography>
      )}
    </Box>
  );
};

export default ReportPage;

// type RankHistory = {
//   date: string;
//   rank: number;
// };

import { url } from "inspector";

type KeywordData = {
  url: string;
  keywordId: string;
  keyword: string;
  rankHistory: RankHistory[];
  createdAt: string;
};

type TransformedData = {
  name: string;
  [keyword: string]: number | string;
};

export const convertToRankHistory = (
  data: KeywordData[]
): TransformedData[] => {
  const result: { [date: string]: TransformedData } = {};

  data.forEach(({ keyword, rankHistory }) => {
    rankHistory.forEach(({ date, rank }) => {
      if (!result[date]) {
        result[date] = { name: date };
      }
      result[date][keyword] = rank;
    });
  });

  return Object.values(result);
};

interface RankHistory {
  date: string;
  rank: number;
}

interface Entry {
  url: string;
  keyword: string;
  rankHistory: {
    [date: string]: RankHistory;
  };
  createdAt: string;
}

interface Data {
  [key: string]: Entry;
}

// Define the output structure
interface Output {
  name: string;
  [keyword: string]: string | number;
}

export const transformRankHistory = (data: Data): Output[] => {
  if (!data || Object.keys(data).length === 0) {
    return [];
  }

  const result: { [date: string]: Output } = {};

  Object.values(data).forEach((entry) => {
    // Check if the entry has rankHistory data
    if (entry.rankHistory) {
      // Loop over each date in rankHistory
      Object.entries(entry?.rankHistory).forEach(([date, { rank }]) => {
        // If the date doesn't exist in the result, initialize it
        if (!result[date]) {
          result[date] = { name: date };
        }
        // Add the keyword and rank to the date entry
        result[date][entry.keyword] = rank;
      });
    }
  });

  const resultArray = Object.values(result);

  // Sort the array by date (the 'name' property)
  resultArray.sort(
    (a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()
  );
  return resultArray;
};
export interface HistoryEntry {
  url: string;
  keyword: string;
  rankHistory: {
    [date: string]: RankHistory;
  };
  createdAt: string;
}

export interface HistoryData {
  [key: string]: Entry;
}

export const transformCompetitorHistory = (
  userSitesHistory: HistoryEntry,
  userCompetitorsHistory: HistoryEntry
): { data: Output[]; keyword: string } => {
  const result: { [date: string]: Output } = {};
  // Loop over the user's history
  // Find the competitor that corresponds to the user entry
  const competitorEntry = userCompetitorsHistory;
  const userEntry = userSitesHistory;
  if (competitorEntry) {
    // Compare rank history of the user and competitor
    userEntry?.rankHistory &&
      Object.entries(userEntry?.rankHistory).forEach(([date, userRank]) => {
        const competitorRank = competitorEntry.rankHistory
          ? competitorEntry.rankHistory[date]
          : null;

        // If both user and competitor have ranks for the same date, process them
        if (competitorRank) {
          // If the date doesn't exist in the result, initialize it
          if (!result[date]) {
            result[date] = { name: date };
          }

          // Add user rank with 'Key' prefix and competitor rank with 'compKey' prefix
          result[date][`אתה`] = userRank.rank;
          result[date][`המתחרה`] = competitorRank.rank;
        }
      });
  }

  // Convert result object into array and sort by date
  return {
    data: Object.values(result).sort(
      (a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()
    ),
    keyword: userEntry.keyword,
  };
};

export const generateDataForGenralChart = (d: any) => {
  const userSiteKey = Object.keys(d.userSitesHistory)?.[0];
  const fullDataChart: {
    ranks: { data: Output[]; keyword: string; url: string }[];
    url: string;
  }[] = [];

  Object.keys(d.userCompetitorsHistory).map((compKey: string) => {
    // Competitor's rank data
    const competitorData =
      d.userCompetitorsHistory[
        compKey as keyof typeof d.userCompetitorsHistory
      ];
    const competitorDataKeys: {
      data: Output[];
      keyword: string;
      url: string;
    }[] = [];
    Object.keys(competitorData)
      .filter((idKeys) => idKeys !== "createdAt") // Filter out 'createdAt'
      .map((rankKey: string) => {
        // Get the corresponding user site data
        const userSiteEntry =
          d.userSitesHistory[userSiteKey as keyof typeof d.userSitesHistory];

        // Ensure that userSiteEntry and rankKey exist
        if (userSiteEntry && rankKey in userSiteEntry) {
          const siteData = userSiteEntry[rankKey as keyof typeof userSiteEntry];
          const competitorRankHistory =
            competitorData[rankKey as keyof typeof competitorData];

          const chartData = transformCompetitorHistory(
            siteData,
            competitorRankHistory as unknown as HistoryEntry
          );
          competitorDataKeys.push({
            ...chartData,
            url: competitorRankHistory.url,
          });
        }
      });
    fullDataChart.push({
      ranks: competitorDataKeys,
      url: competitorDataKeys?.[0].url,
    });
  });
  return fullDataChart;
};

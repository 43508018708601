import axios from "axios";
import { useQuery } from "react-query";
import { baseURL, queryKeys } from "../consts";
import { getToken } from "../authUtils";

const fetchComps = async () => {
  const token = await getToken();
  const data = await axios.get(`${baseURL}/users/competitor-sites`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data?.data;
};

export const useGetComps = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.COMPETITOR],
    queryFn: () => fetchComps(),
  });

  return { data, isLoading, error };
};

import {
    collection,
    query,
    orderBy,
    limit,
    getDocs,
    QueryDocumentSnapshot,
    FirestoreDataConverter,
  } from "firebase/firestore";
  import { db } from "../../firebaseConfig";
import { DomainReport } from "../../Components/Report/domainReport/types";
  
  const converter: FirestoreDataConverter<DomainReport> = {
    toFirestore: (domainReport: DomainReport) => {
      return domainReport;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot) => {
      return snapshot.data() as DomainReport;
    },
  };
  
  export const getLatestDomainReport = async (
    siteId: string
  ): Promise<DomainReport | null> => {
    const domainReportsRef = collection(
      db,
      "sites",
      siteId,
      "domain-checks"
    ).withConverter(converter);
  
    try {
      const q = query(domainReportsRef, orderBy("createdAt", "desc"), limit(1));
  
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return null;
      }
      console.log(querySnapshot.docs[0].data())
      return querySnapshot.docs[0].data();
    } catch (error) {
      console.error("Error fetching latest domain report:", error);
      throw error;
    }
  };
  
import { useMutation } from "react-query";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useAtom, useAtomValue } from "jotai";
import {
  createUserDetails,
  createUserDetailsType,
} from "../../globalAtom/createUserAtom";
import { getToken } from "../authUtils";
import axios from "axios";
import { baseURL } from "../consts";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const createUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredential.user;
};

const fetchUserDetails = async (user: createUserDetailsType) => {
  const token = await getToken();
  debugger;
  const { phoneNumber, fullName, siteUrl } = user;
  const data = await axios.put(
    `${baseURL}/users/details`,
    { phoneNumber: phoneNumber, fullName: fullName, siteUrl: siteUrl },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data?.data;
};

const useCreateUser = () => {
  const userValue = useAtomValue(createUserDetails);
  const [userDetails, setUser] = useAtom(createUserDetails);
  const MySwal = withReactContent(Swal);

  const mutation = useMutation({
    mutationFn: (user: { email: string; password: string }) => createUser(user),
    onSuccess: () => {
      try {
        setUser(undefined);
        fetchUserDetails(userValue as createUserDetailsType);
      } catch (e) {
        setUser(undefined);
        MySwal.fire({
          title: "שגיאה",
          text: "קרתה שגיאת מערכת 3543 , נא לנסות שוב או לפנות לתמיכה טכנית",
          icon: "error",
          confirmButtonText: "בוצע",
        });
        console.error("Error while edit user deatils.", e);
      }
    },
  });
  return mutation;
};

export default useCreateUser;

import React from "react";
import { Box, Typography, Avatar } from "@mui/material";

import "./index.scss";
import { useUserDetails } from "../../api/hooks/useUserDetails";
import LanguageIcon from "@mui/icons-material/Language";
const TopHeader: React.FC = () => {
  const { data, isLoading } = useUserDetails();

  function getInitials(fullName: string) {
    return fullName
      .split(" ")
      .map((name) => name[0])
      .join("");
  }

  return (
    <Box className="topHeader">
      <Typography className="profileName">
        {data && !isLoading && (
          <>
            <Avatar sx={{ bgcolor: "#4792DB" }}>
              {getInitials(data.fullName)}
            </Avatar>
            <Box>
              שלום <strong>{data.fullName}</strong>! 👋 , 🌐 כתובת האתר שלך:{" "}
              <strong>
                <a href={data.site[0]} target="_blank" rel="noreferrer">
                  {data.site[0]}
                </a>
              </strong>
            </Box>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default TopHeader;

import React from "react";
import "./index.scss";
import error from "../../assets/error.png";
import { Box } from "@mui/material";

const Error: React.FC = () => {
  return (
    <Box className="error">
      <img src={error} width="200" />
      חלה שגיאת מערכת בטעינת הנתונים , אנא פנה לתמיכה טכנית
    </Box>
  );
};

export default Error;

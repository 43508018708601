import React, { useCallback, useMemo, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AssessmentIcon from "@mui/icons-material/Assessment";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import logo from "../../../assets/fullLogo.png";
import { auth } from "../../../firebaseConfig";

const SideNavbarMobile: React.FC = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  const location = useLocation(); // Get the current URL

  const currentKeyFunc = useCallback(() => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  }, [location.pathname]);

  const currentKey = useMemo(() => currentKeyFunc(), [currentKeyFunc]);

  return (
    <nav role="navigation" className="navigation">
      <div className="nav-logo-top">
        <div className="mobile-logo">
          <img src={logo} width="200" alt="logo" />
        </div>
        <div id="menuToggle">
          <input type="checkbox" id="menuCheckbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <li>
              <div className="mobile-logo">
                <img src={logo} width="200" alt="logo" />
              </div>
            </li>
            <li>
              <Link to="statistics">
                <label>סטטיסטיקות</label>
              </Link>
            </li>
            <li>
              <Link to="reports">
                <label>דוחות</label>
              </Link>
            </li>
            <li>
              <Link to="keys">
                <label>מילות מפתח</label>
              </Link>
            </li>
            <li>
              <Link to="comps">
                <label>מתחרים</label>
              </Link>
            </li>

            <li>
              <Link to="/login" onClick={handleLogout}>
                <label>התנתקות</label>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SideNavbarMobile;

import { getAuth } from "firebase/auth";

// Function to get the token of the current user
export const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      const token = await user.getIdToken();
      return token;
    } catch (error) {
      console.error("Error getting token:", error);
      throw error;
    }
  } else {
    console.error("No user is signed in");
  }
};

import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { queryKeys } from "../../../api/consts";
import { useQueryClient } from "react-query";
import AddCompetitorsDialog from "./AddCompetitorDialog";
import "./index.scss";
import { useDeleteComps } from "../../../api/hooks/useDeleteComp";
import { formatDate } from "../../../utils";
import InfoIcon from "@mui/icons-material/Info";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { useUserDetails } from "../../../api/hooks/useUserDetails";
type Props = {
  data: any;
  subscription: any;
};
const CompetitorsTable: React.FC<Props> = ({ data, subscription }) => {
  const queryClient = useQueryClient();

  const onSuccessHandler = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.COMPETITOR] });
  };
  const mutation = useDeleteComps(onSuccessHandler);

  const [open, setOpen] = useState(false);
  const [keywordAdded, setCompAdded] = useState();

  const competitorLimit =
    data.length >= subscription?.competitor_limit || false;
  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Button
        variant="outlined"
        disabled={competitorLimit}
        onClick={() => setOpen(true)}
      >
        הוספת מתחרים
      </Button>
      {competitorLimit && (
        <div>
          <Typography variant="overline">
            החבילה שלך תומכת עד{" "}
            <strong>{subscription?.competitor_limit}</strong> מתחרים
          </Typography>
        </div>
      )}
      <AddCompetitorsDialog
        openDialog={open}
        setDialogOpen={setOpen}
        setCompAdded={setCompAdded}
      />
      <TableContainer component={Paper} sx={{ marginTop: "1rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="header">
              <TableCell>כתובת מתחרה</TableCell>
              <TableCell align="center" className="center">
                תאריך הוספה{" "}
                <Tooltip title="תאריך שבו הוספתם כתובת המתחרה">
                  <InfoIcon />
                </Tooltip>
              </TableCell>{" "}
              <TableCell align="right">אפשרויות</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((comp: any) => (
                <TableRow
                  key={comp.url}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b style={{ color: "#4792db" }}>{comp.url}</b>
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(comp.createdAt)}
                  </TableCell>

                  <TableCell align="right">
                    {!mutation.isLoading ? (
                      <>
                        <Tooltip title="מחיקת מתחרה">
                          <DeleteIcon
                            onClick={() => {
                              mutation.mutate(comp.url);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="מעבר לאתר המתחרה">
                          <PanToolAltIcon
                            onClick={() => {
                              comp.url &&
                                window
                                  .open(comp?.url as string, "_blank")
                                  ?.focus();
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompetitorsTable;

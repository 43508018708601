import React, { useState } from "react";
import "./index.scss";
import KeywordsChart from "../Charts/KeywordsChart";
import KeywordsBigChart from "../Charts/KeywordsBigChart";
import DataCard from "../../../Components/DataCard";
import { formatDate } from "../../../utils";
import {
  generateDataForGenralChart,
  transformRankHistory,
} from "../../../utils/convertDataToCharts";
import { Box, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

type Props = {
  d: any;
  isLoading: boolean;
  userDataSubscription: any;
};

const SubscriptionPackageMap = {
  pro: "מתקדמת",
  basic: "בסיסית",
};
const MainCharts: React.FC<Props> = ({
  d,
  isLoading,
  userDataSubscription,
}) => {
  const userSiteKey = Object.keys(d.userSitesHistory)?.[0];
  const s = transformRankHistory(d.userSitesHistory[userSiteKey]);
  const fullData = generateDataForGenralChart(d);

  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const accordionClicked = (index: number) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number: number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray: any = [];
    fullData.forEach((log: any, index: any) => newArray.push(index));
    setExpandedAccordions(newArray);
  };
  return (
    <Box sx={{ width: "100%", maxWidth: "100vw", overflow: "hidden" }}>
      {d && !isLoading && (
        <>
          <Box
            className="topStats"
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              gap: 2,
              mb: 3,
              p: 2,
            }}
          >
            <DataCard text={"כמות מילות מפתח"} data={d.keywordCount} />
            <DataCard text={"כמות מתחרים"} data={d.competitorCount} />
            <DataCard text={"תאריך התחלה"} data={formatDate(d.userCreatedAt)} />
            <DataCard
              text={"סוג החבילה"}
              data={
                SubscriptionPackageMap[
                  userDataSubscription?.type as keyof typeof SubscriptionPackageMap
                ]
              }
            />
          </Box>

          <Box className="charts" sx={{ mb: 3 }}>
            <KeywordsChart data={s} text="מיקום מילות החיפוש שלך" />
          </Box>
          <Box className="expandManager">
            <Typography
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={expandAll}
            >
              פתח הכל
              <ArrowDownwardIcon />
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={collapseAll}
            >
              סגור הכל
            </Typography>
          </Box>
          {fullData.map((data, index) => (
            <Box
              key={index}
              className="bigChart--noData"
              sx={{
                mb: 3,
                "&:last-child": { mb: 0 },
              }}
            >
              <KeywordsBigChart
                data={data.ranks}
                text={`🐱‍👤 המתחרה: ${data.url}`}
                accordionClicked={accordionClicked}
                expandedAccordions={expandedAccordions}
                index={index}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default MainCharts;

import React from "react";
import SideNavbar from "../../Components/SideNavbar";
import "./index.scss";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import TopHeader from "../../Components/TopHeader";
import Footer from "../../Components/Footer";
import SideNavbarMobile from "../../Components/SideNavbar/MobileNavbar";

const Dashboard: React.FC = () => {
  return (
    <>
      <SideNavbar />
      <SideNavbarMobile />
      <Box className="mainDashboard">
        <TopHeader />
        <Outlet />
        <Footer />
      </Box>
    </>
  );
};

export default Dashboard;

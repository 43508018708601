import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "./index.scss";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../../../api/consts";
import { useAddComp } from "../../../../api/hooks/useAddComp";

type Props = {
  openDialog: boolean;
  setDialogOpen: any;
  setCompAdded: any;
};

const AddCompetitorsDialog: React.FC<Props> = ({
  openDialog,
  setDialogOpen,
  setCompAdded,
}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);

  const onSuccessHandler = () => {
    queryClient.refetchQueries({ queryKey: [queryKeys.COMPETITOR] });
    queryClient.refetchQueries({
      queryKey: [queryKeys.HISTORY],
    });
    setError(null);
    setCompAdded(text);
    setDialogOpen(false);
  };

  const onErrorHandler = (err: any) => {
    const apiError = err.response?.data?.error;

    if (apiError?.startsWith("Invalid URL format")) {
      setError("שגיאה בהוספת מתחרה: הכתובת אינה תקנית.");
    } else {
      const message =
        err.response?.data?.message || "הייתה שגיאה בהוספת המתחרה.";
      setError(message);
    }
  };

  const { mutate, isLoading } = useAddComp(onSuccessHandler, onErrorHandler);
  const [text, setText] = useState("");

  return (
    <Dialog
      open={openDialog}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">הוספת מילת חיפוש</DialogTitle>

      <DialogContent>
        {isLoading ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography>המערכת מוסיפה את המתחרים</Typography>
          </Box>
        ) : (
          <DialogContentText id="alert-dialog-description">
            הזן את הכתובת של המתחרה ולחץ על הוספה
            <Tooltip
              title="הכתובת צריכה להיות מלאה ולהתחיל ב-http או https (למשל: https://www.example.com)"
              arrow
            >
              <InfoIcon />
            </Tooltip>
            <TextField
              label="כתובת המתחרה"
              type="text"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={text}
              onChange={(e) => setText(e.target.value)}
              error={!!error}
              helperText={error}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? null : (
          <>
            <Button onClick={() => setDialogOpen(false)}>ביטול</Button>
            <Button
              onClick={() => {
                mutate(text);
              }}
              autoFocus
            >
              הוספה
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddCompetitorsDialog;

import axios from "axios";
import { useMutation } from "react-query";
import { baseURL } from "../consts";
import { getToken } from "../authUtils";

const fetchKeywords = async (keyword: string) => {
  const token = await getToken();
  const data = await axios.post(
    `${baseURL}/users/keywords`,
    { keyword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data?.data;
};

export const useAddKeyword = (onSuccess: any, onError?: any) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: (keyword: string) => fetchKeywords(keyword),
    onSuccess: onSuccess,
  });

  return { mutate, isLoading };
};

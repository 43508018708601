import axios from "axios";
import { useQuery } from "react-query";
import { baseURL, queryKeys } from "../consts";
import { getToken } from "../authUtils";

const fetchHistory = async () => {
  const token = await getToken();
  const data = await axios.get(`${baseURL}/users/keywords/history`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data?.data;
};

export const useGetHistory = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.HISTORY],
    queryFn: () => fetchHistory(),
    staleTime: Infinity,
  });

  return { data, isLoading, error };
};

import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import "./index.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box className="main">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} width="120" alt="logo" />
          <Typography variant="h6">
            ברוכים הבאים ל - <strong>WeSeo</strong>
          </Typography>

          <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
            <Typography variant="subtitle2">
              על מנת להתחבר למערכת יש להזין את הפרטים הבאים
            </Typography>
            <TextField
              label="אימייל"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="סיסמא"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" fullWidth variant="outlined" sx={{ mt: 2 }}>
              התחברות
            </Button>
          </Box>
          <strong>
            <small>
              <Link to="/register">להרשמה לחץ פה</Link>
            </small>
          </strong>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

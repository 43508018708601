import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tooltip as MuiTooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./index.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const COLORS = [
  "#0bb4ff",
  "#e60049",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

const COLORS_FADE = [
  "#e6004994",
  "#0bb4ff94",
  "#50e991cf",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

type Props = {
  data: any;
  text: string;
  accordionClicked: any;
  expandedAccordions: any;
  index: number;
};

const KeywordsBigChart: React.FC<Props> = ({
  data,
  text,
  accordionClicked,
  expandedAccordions,
  index,
}) => {
  const [chartData, setData] = useState(data[0]);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    const norData = chartData.data.map((obj: any) =>
      Object.keys(obj).filter((key) => key !== "name")
    );
    setKeys(Array.from(new Set(norData.flat())));
  }, [chartData, data]);

  const hasData = keys && keys.length > 0;

  return (
    <Box sx={{ width: "100%", marginTop: "2rem" }}>
      <Accordion
        onChange={() => accordionClicked(index)}
        expanded={expandedAccordions.includes(index)}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">
            <b style={{ color: "#00000" }}>{text}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div style={{ marginBottom: "20px" }}>
              {data.map((d: any, index: number) => (
                <MuiTooltip
                  key={index}
                  title={
                    data[index].data.length === 0
                      ? "אין נתונים להצגה"
                      : "מעבר למילת חיפוש"
                  }
                >
                  <span
                    style={{
                      marginRight: "15px",
                      cursor: "pointer",
                      color:
                        chartData.keyword === data[index].keyword
                          ? "rgb(71, 146, 219)"
                          : data[index].data.length === 0
                          ? "grey"
                          : "black",
                      fontWeight:
                        chartData.keyword === data[index].keyword
                          ? "bold"
                          : "normal",
                    }}
                    onClick={() =>
                      data[index].data.length === 0
                        ? null
                        : setData(data[index])
                    }
                  >
                    {d.keyword}
                  </span>
                </MuiTooltip>
              ))}
            </div>
            {keys && keys.length > 0 ? (
              <div style={{ width: "100%", height: "200px" }}>
                <ResponsiveContainer>
                  <LineChart
                    data={chartData.data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      type="number"
                      reversed
                      tickFormatter={(value) => Math.round(value).toString()}
                    />
                    <Tooltip />
                    <Legend />
                    {keys.map((d: string, index: number) => (
                      <Line
                        key={d}
                        type="monotone"
                        dataKey={d}
                        stroke={COLORS[index]}
                        fill={COLORS_FADE[index]}
                        strokeWidth={2}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <Typography variant="h5">
                <b>❌ המתחרה לא קיים במילת החיפוש הזו. </b>
              </Typography>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default KeywordsBigChart;

import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  QueryDocumentSnapshot,
  FirestoreDataConverter,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { SSLCheck } from "../../Components/Report/sslReport/types";

const converter: FirestoreDataConverter<SSLCheck> = {
  toFirestore: (sslCheck: SSLCheck) => {
    return sslCheck;
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return snapshot.data() as SSLCheck;
  },
};

export const getLatestSSLCheck = async (
  siteId: string
): Promise<SSLCheck | null> => {
  const sslChecksRef = collection(
    db,
    "sites",
    siteId,
    "ssl-checks"
  ).withConverter(converter);

  try {
    const q = query(sslChecksRef, orderBy("createdAt", "desc"), limit(1));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    return querySnapshot.docs[0].data();
  } catch (error) {
    console.error("Error fetching latest SSL check:", error);
    throw error;
  }
};

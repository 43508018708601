import React from "react";
import { Box, CardContent, Skeleton, Typography } from "@mui/material";
import "./index.scss";
import LoadingPattern from "../../Components/LoadingPattern";
import CompetitorsTable from "./CompetitorsTable";
import { useGetComps } from "../../api/hooks/useGetComps";
import Error from "../../Components/Error";
import FaceRetouchingOffIcon from "@mui/icons-material/FaceRetouchingOff";
import { useUserDetails } from "../../api/hooks/useUserDetails";
const Competitors: React.FC = () => {
  const { data, isLoading, error } = useGetComps();
  const { data: userData, isLoading: subLoading } = useUserDetails();

  return (
    <>
      {!error && (
        <Box sx={{ padding: 2 }} className="mainCharts">
          <Typography component="h1" className="textHeader">
            <FaceRetouchingOffIcon className="topIcon" /> מתחרים
          </Typography>
          <React.Fragment>
            <CardContent className="keywordCard">
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                כמות המתחרים שלך
              </Typography>
              <Typography variant="h5" component="div">
                <Box component="span" className="current">
                  {!isLoading && !subLoading ? (
                    <>
                      <span style={{ color: "black" }}>
                        {userData.subscription?.competitor_limit || 0} /
                      </span>{" "}
                      {data?.length}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Box>
              </Typography>
            </CardContent>
          </React.Fragment>
          <Box maxWidth={"xl"}>
            {!isLoading ? (
              <CompetitorsTable
                data={data}
                subscription={userData?.subscription}
              />
            ) : (
              <LoadingPattern />
            )}
          </Box>
        </Box>
      )}
      {error && <Error />}
    </>
  );
};

export default Competitors;

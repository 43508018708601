import { CacheProvider } from "@emotion/react";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import { createTheme, ThemeProvider } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { QueryClient, QueryClientProvider } from "react-query";

const theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Open Sans Hebrew",
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="App" dir="rtl">
            <AppRoutes />
          </div>
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
}

export default App;
